import React, { Component } from "react";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";

export default class Table extends Component {
  createData = ([
    tconst,
    titleType,
    primaryTitle,
    originalTitle,
    startYear,
    runtimeMinutes,
    genres,
    averageRating,
    numVotes,
  ]) => {
    return {
      id: tconst,
      tconst,
      titleType,
      primaryTitle,
      originalTitle,
      startYear,
      runtimeMinutes,
      genres,
      averageRating,
      numVotes,
    };
  };
  render() {
    const col = this.props.col;
    const dat = this.props.data;

    const columns = [];
    if (col)
      col.forEach((el, i) => {
        // if (i>1)
        columns.push({
          field: el,
          headerName: el,
          width: 200,
          editable: false,
        });
      });
    const rows = [];
    if (dat)
      dat.forEach((el) => {
        rows.push(this.createData(el));
      });
    return (
      <div style={{ height: "100vh", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={25}
          disableSelectionOnClick
          components={{
            Toolbar: GridToolbar,
          }}
        />
      </div>
    );
  }
}
