import React from "react";
import { Grid } from "@material-ui/core";
import data from "../data/out.json";
import Table from "./Table";
export default function DataWrapper() {
  const extractColumns = () => {
    return (data && data.columns) || [];
  };
  const extractData = () => {
    return (data && data.data) || [];
  };
  const cols = extractColumns();
  const dat = extractData();
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Table col={cols} data={dat} />
      </Grid>
    </Grid>
  );
}
